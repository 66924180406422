import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {removeFromWishlist, addToCartFromWishlist} from '../../../redux/actions';
import {useAppState} from "../../../contexts/app/app.provider";

function WishlistMenuSidebar(props) {

  const pct = useAppState('pct');
  const addToCartBtnText = useAppState('addToCartBtnText');
  const currencySymbol = useAppState('currencySymbol');
  const showPrice = pct && pct.productSettings && pct.productSettings.showPrice;
  const showQuantity = pct && pct.productSettings && pct.productSettings.showQuantity;

  const {wishlist} = props;

  return (
    <>
      <div className={`offcanvas-minicart_wrapper ${props.visible ? "open" : ""}`} id="miniCart">
        <div className="offcanvas-menu-inner">
          <div className="close-btn-box">
            <a className="btn-close"
               onClick={e => {
                 props.toggle(!props.visible);
               }}
            >
              <i className="icon-cross2"/>
            </a>
          </div>

          <div className="cart-title">
            <h5>Wishlist</h5>
          </div>

          {
            0 === wishlist.length ? <p>No products in the wishlist.</p> : <>
              <div className="minicart-content">
                <ul className="minicart-list">
                  {
                    wishlist.map((item, index) => {
                      return <li className="minicart-product" key={index}>
                          <a
                            className="product-item_remove"
                            onClick={e => {
                              console.log("clicked")
                              console.log("item = ", item);
                              e && e.preventDefault();
                              props.removeFromWishlist(item.productId)
                            }}
                          >
                            <i className="icon-cross2"/>
                          </a>
                          <a className="product-item_img">
                            <img width="76px" className="img-fluid" src={`${process.env.CLOUD_FRONT_URL}${item.thumb}`}
                                 alt={item.name}/>
                          </a>
                          <div className="product-item_content">
                            <div className="product-item_title">{item.name}</div>
                            {
                              showPrice && <label className="product-item_quantity">
                                Price: <span> {currencySymbol}
                                {(item.priceValue || 0).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2
                                })}
                              </span>
                              </label>
                            }
                            <a
                              href={"#"}
                              className="btn btn--black btn--sm"
                              onClick={(e) => {
                                e.preventDefault()
                                props.addToCartFromWishlist(item, (showQuantity ? 1 : 0))
                              }}
                            >
                              {addToCartBtnText}
                            </a>
                          </div>
                        </li>
                    })
                  }
                  {/*<li>*/}
                  {/*  <img className="img-fluid" src="assets/images/products/thumb/1.jpg"*/}
                  {/*       alt="product"/>*/}
                  {/*  <div className="product-meta">*/}
                  {/*    <h5 className="product-title">Hebes Great Chair</h5>*/}
                  {/*    <p className="product-qunt">Quantity: 01</p>*/}
                  {/*    <p className="product-price">$24.00</p>*/}
                  {/*  </div>*/}
                  {/*  <Link className="cart-cancel" to="#"><i className="lnr lnr-cross"></i></Link>*/}
                  {/*</li>*/}
                  {/*<li>*/}
                  {/*  <img className="img-fluid" src="assets/images/products/thumb/2.jpg"*/}
                  {/*       alt="product"/>*/}
                  {/*  <div className="product-meta">*/}
                  {/*    <h5 className="product-title">Hebes Great Chair</h5>*/}
                  {/*    <p className="product-qunt">Quantity: 01</p>*/}
                  {/*    <p className="product-price">$24.00</p>*/}
                  {/*  </div>*/}
                  {/*  <Link className="cart-cancel" to="#"><i className="lnr lnr-cross"></i></Link>*/}
                  {/*</li>*/}
                </ul>
              </div>
            </>
          }
        </div>

        <div className={`global-overlay ${props.visible ? "overlay-open" : ""}`}/>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    wishlist: state.wishlist.list
  }
}

export default connect(mapStateToProps, {
  removeFromWishlist,
  addToCartFromWishlist
})(WishlistMenuSidebar);