import React, {useEffect} from 'react';
import {ToastContainer} from 'react-toastify';
import {withRouter} from 'react-router-dom';
// import Custom Components
import Header from './common/header';
import Footer from './common/footer';
// import Utils
// import Actions

// import data

function App(props) {

  useEffect(() => {
    window.scrollTo({
      top: 0,
    })
  }, []);

  return (
    <>

      <Header isHomePage={!props.notHomePage}/>
      <div id="main-wrapper">
        <div className="site-wrapper-reveal wrapper clearfix">
          {
            props.children
          }
          <Footer/>
        </div>
      </div>

      {/*<div className="wrapper clearfix">*/}
      {/*  <Header/>*/}
      {/*  {*/}
      {/*    props.children*/}
      {/*  }*/}
      {/*  <Footer/>*/}
      {/*</div>*/}

      {/*<div className="page-wrapper">*/}
      {/*  <Header container={container} urls={prevPath}/>*/}
      {/*  <div className={'main-container'} ref={mainContainer}>*/}
      {/*    {*/}
      {/*      props.children*/}
      {/*    }*/}
      {/*  </div>*/}

      {/*  <Footer container={container} footerEle={footerEle}/>*/}

      <ToastContainer autoClose={3000} className="toast-container"/>

      {/*  <AddToCartModal/>*/}
      {/*</div>*/}

      {/*<MobileMenu/>*/}

    </>
  );
}

export default withRouter(App);

