import axios from 'axios';

class _AxiosMaster {
  constructor({token, base}) {
    this.base = process.env.ADMIN_API_URL;
    this.instance = axios.create({
      baseURL: this.base,
      timeout: 30000,
      headers: {
        'Content-Type': 'image/png',
        "x-access-token": localStorage.getItem('pct'),
        // "Authorization": Authorization
      }
    });
  }

  // xhr.setRequestHeader('Content-Type', 'image/png');

  get(url) {
    this.instance.defaults.headers['Authorization'] = localStorage.getItem('token');
    return this.instance.get(url)
  };

  postRequest(url, params) {
    this.instance.defaults.headers['Authorization'] = localStorage.getItem('token');
    return params ? this.instance.post(url, params) : this.instance.post(url)
  };

  uploadFileUsingSignUrl(file, url) {
    return this.instance.put(url, file);
  };

  getDirect(url) {
    this.instance.defaults.headers['Authorization'] = localStorage.getItem('token');
    return axios.get(url)
  }

}

const AxiosMaster = _AxiosMaster;
export default AxiosMaster;
