import React, { useEffect } from "react";
import { useAppState } from "../../contexts/app/app.provider";
import { Link } from "react-router-dom";
import logoImage from "../../assets/images/goldenpankh/logo/logo1.1.png";

function Footer(props) {
  const pct = useAppState("pct");
  const companyName = useAppState("companyName");
  const socialLinks = useAppState("socialLinks");
  const { container = "container" } = props;

  const socialNameIconMapper = {
    facebook: "facebook",
    twitter: "twitter",
    pinterest: "pinterest-p",
    instagram: "instagram",
    linkedinS: "linkedin-square",
    youtube: "youtube-play",
    whatsapp: "whatsapp",
    google: "google",
  };

  useEffect(() => {
    var $ = window.jQuery;
    var siteContent = $("#wrapperParallax");
    var contentParallax = $(".contentParallax");
    var siteFooter = $("#footerParallax");
    var siteFooterHeight = siteFooter.height();
    siteContent.css({
      "margin-bottom": siteFooterHeight,
    });
  }, []);

  return (
    <>
      {/*<footer id="footer" className="footer">*/}
      {/*  <div className="container pr-40 pl-40">*/}
      {/*    <div className="footer-widget">*/}
      {/*      <div className="row mb-50">*/}
      {/*        <div*/}
      {/*          className="col-sm-12 col-md-12 col-lg-4  widget--logo text-center-xs mb-4 d-flex justify-content-center">*/}
      {/*          <div className="widget--content">*/}
      {/*            <div*/}
      {/*              className="align-items-center d-flex flex-column justify-content-center flex-wrap">*/}
      {/*              <img*/}
      {/*                //src={`${process.env.CLOUD_FRONT_URL}${pct.logo}`}*/}
      {/*                alt={companyName}*/}
      {/*                style={{*/}
      {/*                  height: '60px',*/}
      {/*                  // marginRight: '15px',*/}
      {/*                  display: 'block',*/}
      {/*                  maxWidth: '270px',*/}
      {/*                  objectFit: 'contain'*/}
      {/*                }}*/}
      {/*              /> <h6 className={'footer-company-name'}>{companyName}</h6>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </div>*/}

      {/*        <div*/}
      {/*          className="col-sm-12 col-md-6 col-lg-4  text-center-xs widget--contact-info mb-4 d-flex  justify-content-center">*/}
      {/*          <div className="widget--content">*/}
      {/*            <ul className="list-unstyled">*/}
      {/*              <li className="widget-title">Contact</li>*/}
      {/*              {*/}
      {/*                pct.email && <li><a href={`mailto:arbab@paramounthomecollections.com`}>arbab@paramounthomecollections.com</a></li>*/}
      {/*              }*/}
      {/*              {*/}
      {/*                pct.phone && <li><a href={`https://wa.me/+919897682000`}>+91 98976 82000</a></li>*/}
      {/*              }*/}
      {/*            </ul>*/}
      {/*          </div>*/}
      {/*        </div>*/}

      {/*        /!*<div className="col-sm-12 col-md-6 col-lg-3  text-center-xs widget--links">*!/*/}
      {/*        /!*  <div className="widget--content">*!/*/}
      {/*        /!*    <ul className="list-unstyled">*!/*/}
      {/*        /!*      <li><a href="page-about-1.html">About Us </a></li>*!/*/}
      {/*        /!*      <li><a href="page-privacy.html">Privacy Policy</a></li>*!/*/}
      {/*        /!*      <li><a href="page-terms.html">Term & Conditions</a></li>*!/*/}
      {/*        /!*      <li><a href="#">Help</a></li>*!/*/}
      {/*        /!*    </ul>*!/*/}
      {/*        /!*  </div>*!/*/}
      {/*        /!*</div>*!/*/}

      {/*        <div*/}
      {/*          className="col-sm-12 col-md-6 col-lg-4   text-center-xs widget--social mb-4 d-flex  justify-content-center">*/}
      {/*          <div className="widget--content">*/}
      {/*            <div className="social--icons">*/}
      {/*              <div className="widget-title">*/}
      {/*                Follow Us On Social*/}
      {/*              </div>*/}
      {/*              {*/}
      {/*                socialLinks && socialLinks.length ?*/}
      {/*                  <div className="pt-1">*/}
      {/*                    {*/}
      {/*                      socialLinks.map((it, index) => {*/}

      {/*                        if (!socialNameIconMapper[it.name]) {*/}
      {/*                          return null;*/}
      {/*                        }*/}

      {/*                        return <a key={index} className={it.name} href={it.url} target="_blank">*/}
      {/*                          <i className={`fa fa-${socialNameIconMapper[it.name]}`}></i>*/}
      {/*                        </a>*/}
      {/*                      })*/}
      {/*                    }*/}
      {/*                  </div>*/}
      {/*                  : ''*/}
      {/*              }*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </div>*/}

      {/*      </div>*/}
      {/*      <div className="row">*/}
      {/*        <div className="col-sm-12">*/}
      {/*          <div className="footer--copyright text-center">*/}
      {/*            <span>*/}
      {/*              Copyright © {(new Date()).getFullYear()} {companyName}. All Rights Reserved.*/}
      {/*            </span>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}

      {/*  </div>*/}

      {/*</footer>*/}
      {/*footer area start here----*/}
      <div className="footer-area-wrapper  bg-gray mt-5">
        <div className="footer-area section-space--ptb_90">
          <div className="container-fluid container-fluid--cp-100">
            <div className="row footer-widget-wrapper">
              <div className="col-lg-3 col-md-4 col-sm-6 footer-widget">
                <div className="col-lg-3 col-lg-3 col-6">
                  <div className="logo text-start">
                    <Link to="/">
                      <img src={logoImage} alt="logo" />
                    </Link>
                  </div>
                </div>
                <p className="mt-2">
                  Copyright @ 2022 - All Rights <br /> Reserved, by
                  <span style={{ color: "#606da6" }}> goldenpankh</span>
                </p>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 footer-widget">
                <div className="footer_box">
                  <h6 className="footer_heading mb-3">
                    Colden Pankh Export Import
                  </h6>
                  <div className="footer_linkbox">
                    <ul className="footer_hours">
                      <li>
                        D-8, Harthala Industrial Estate,
                        <p>Kanth Road, Moradabad 244001,</p>
                        <p>Uttar Pradesh India </p>
                      </li>
                      <li>
                        naresh@goldenpankh.com<p>&nbsp;</p>
                      </li>
                      <li>
                        +91-591-2970888<p>+91-7417555555</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 offset-lg-1 col-md-4 col-sm-6 footer-widget">
                <ul className="footer-widget__list">
                  <li>
                    <a href="about.html" className="hover-style-link">
                      About Us
                    </a>
                  </li>
                  <li>
                    <a href="contact.html" className="hover-style-link">
                      Contact
                    </a>
                  </li>
                  <li>
                    <a href="contact.html" className="hover-style-link">
                      Terms & Conditions
                    </a>
                  </li>
                  <li>
                    <a href="contact.html" className="hover-style-link">
                      Privacy Policy
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 footer-widget">
                <div className="footer_box">
                  <h6 className="footer_heading mb-3">Follow us On Social</h6>
                  <div className="footer_linkbox">
                    <ul className="list footer-social-networks ">
                      <li className="item">
                        <a
                          href="https://www.facebook.com/Golden-Pankh-Export-Import-349233159129646/"
                          target="_blank"
                          aria-label="Facebook"
                        >
                          <i className="social social_facebook"></i>
                        </a>
                      </li>
                      <li className="item" style={{ fontSize: "20px" }}>
                        <a
                          href="https://api.whatsapp.com/send?phone=917417555555&text=I+have+a+query+regarding+Golden+Pankh"
                          target="_blank"
                          aria-label="Whatsapp"
                        >
                          <i className="fa fa-whatsapp" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <hr /> */}
          {/* <div className="footer-copyright-area section-space--ptb_30">
            <div className="container-fluid container-fluid--cp-100">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-6 order-md-1 order-2">
                  <span className="copyright-text text-center text-md-start">
                    <p>
                      Copyright © 2020
                      <a aria-current="page" className="active" href="/">
                        {" Golden Pankh Export Import. "}
                      </a>
                      All Right Reserved.
                    </p>
                  </span>
                </div>
                <div className="col-lg-6 col-md-6 order-md-2 order-1">
                  <div className="footer-bottom-social">
                    <h6 className="title">Follow Us On Social</h6>
                    <ul className="list footer-social-networks ">
                      <li className="item">
                        <a
                          href="https://www.facebook.com/Golden-Pankh-Export-Import-349233159129646/"
                          target="_blank"
                          aria-label="Facebook"
                        >
                          <i className="social social_facebook"></i>
                        </a>
                      </li>
                      <li className="item" style={{ fontSize: "20px" }}>
                        <a
                          href="https://api.whatsapp.com/send?phone=917417555555&text=I+have+a+query+regarding+Golden+Pankh"
                          target="_blank"
                          aria-label="Whatsapp"
                        >
                          <i className="fa fa-whatsapp" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {/*footer area end here-------*/}

      {false && (
        <footer className="footer footer-2" ref={props.footerEle}>
          {/*<div className="footer-middle">*/}
          {/*    <div className={ container }>*/}
          {/*        <div className="row">*/}
          {/*            <div className="col-sm-12 col-lg-4">*/}
          {/*                <div className="widget widget-about">*/}
          {/*                    <img src={ `${process.env.PUBLIC_URL}/assets/images/logo-footer.png` } className="footer-logo" alt="Footer Logo" width="105" height="25" />*/}
          {/*                    <p>Praesent dapibus, neque id cursus ucibus, tortor neque egestas augue, eu vulputate magna eros eu erat. Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan porttitor, facilisis luctus, metus. </p>*/}

          {/*                    <div className="widget-about-info">*/}
          {/*                        <div className="row">*/}
          {/*                            <div className="col-sm-6 col-md-4">*/}
          {/*                                <span className="widget-about-title">Got Question? Call us 24/7</span>*/}
          {/*                                <Link to="tel:123456789">+0123 456 789</Link>*/}
          {/*                            </div>*/}
          {/*                            <div className="col-sm-6 col-md-8">*/}
          {/*                                <span className="widget-about-title">Payment Method</span>*/}
          {/*                                <figure className="footer-payments">*/}
          {/*                                    <img src={ `${process.env.PUBLIC_URL}/assets/images/payments.png` } alt="Payment methods" width="272" height="20" />*/}
          {/*                                </figure>*/}
          {/*                            </div>*/}
          {/*                        </div>*/}
          {/*                    </div>*/}
          {/*                </div>*/}
          {/*            </div>*/}

          {/*            <div className="col-sm-4 col-lg-2">*/}
          {/*                <div className="widget">*/}
          {/*                    <h4 className="widget-title">Useful Links</h4>*/}

          {/*                    <ul className="widget-list">*/}
          {/*                        <li><Link to={ `${process.env.PUBLIC_URL}/about` }>About {companyName}</Link></li>*/}
          {/*                        <li><Link to={ `${process.env.PUBLIC_URL}/pages/about` }>How to shop on {companyName}</Link></li>*/}
          {/*                        <li><Link to={ `${process.env.PUBLIC_URL}/pages/faq` }>FAQ</Link></li>*/}
          {/*                        <li><Link to={ `${process.env.PUBLIC_URL}/pages/contact` }>Contatc us</Link></li>*/}
          {/*                        <li><Link to={ `${process.env.PUBLIC_URL}/pages/login` }>Log in</Link></li>*/}
          {/*                    </ul>*/}
          {/*                </div>*/}
          {/*            </div>*/}

          {/*            <div className="col-sm-4 col-lg-2">*/}
          {/*                <div className="widget">*/}
          {/*                    <h4 className="widget-title">Customer Service</h4>*/}

          {/*                    <ul className="widget-list">*/}
          {/*                        <li><Link to="#">Payment Methods</Link></li>*/}
          {/*                        <li><Link to="#">Money-back guarantee!</Link></li>*/}
          {/*                        <li><Link to="#">Returns</Link></li>*/}
          {/*                        <li><Link to="#">Shipping</Link></li>*/}
          {/*                        <li><Link to="#">Terms and conditions</Link></li>*/}
          {/*                        <li><Link to="#">Privacy Policy</Link></li>*/}
          {/*                    </ul>*/}
          {/*                </div>*/}
          {/*            </div>*/}

          {/*            <div className="col-sm-4 col-lg-2">*/}
          {/*                <div className="widget">*/}
          {/*                    <h4 className="widget-title">My Account</h4>*/}

          {/*                    <ul className="widget-list">*/}
          {/*                        <li><Link to={ `${process.env.PUBLIC_URL}/pages/login` }>Log in</Link></li>*/}
          {/*                        <li><Link to={ `${process.env.PUBLIC_URL}/cart` }>View Cart</Link></li>*/}
          {/*                        <li><Link to={ `${process.env.PUBLIC_URL}/shop/wishlist` }>My Wishlist</Link></li>*/}
          {/*                        <li><Link to="#">Track My Order</Link></li>*/}
          {/*                        <li><Link to="#">Help</Link></li>*/}
          {/*                    </ul>*/}
          {/*                </div>*/}
          {/*            </div>*/}

          {/*            <div className="col-sm-6 col-lg-2">*/}
          {/*                <div className="widget widget-newsletter">*/}
          {/*                    <h4 className="widget-title">Sign Up To Newsletter</h4>*/}

          {/*                    <p>Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan.</p>*/}

          {/*                    <form action="#">*/}
          {/*                        <div className="input-group">*/}
          {/*                            <input type="email" className="form-control" placeholder="Enter your Email Address" aria-label="Email Adress" required />*/}
          {/*                            <div className="input-group-append">*/}
          {/*                                <button className="btn btn-dark" type="submit"><i className="icon-long-arrow-right"></i></button>*/}
          {/*                            </div>*/}
          {/*                        </div>*/}
          {/*                    </form>*/}
          {/*                </div>*/}
          {/*            </div>*/}
          {/*        </div>*/}
          {/*    </div>*/}
          {/*</div>*/}

          <div className="footer-bottom">
            <div className={container}>
              <p className="footer-copyright">
                Copyright © {new Date().getFullYear()} {companyName}. All Rights
                Reserved.
              </p>
              {/*<ul className="footer-menu">*/}
              {/*    <li><Link to="#">Terms Of Use</Link></li>*/}
              {/*    <li><Link to="#">Privacy Policy</Link></li>*/}
              {/*</ul>*/}

              {/*<div className="social-icons social-icons-color">*/}
              {/*    <span className="social-label">Social Media</span>*/}
              {/*    <a href="https://www.facebook.com" className="social-icon social-facebook" rel="noopener noreferrer" title="Facebook" target="_blank"><i className="icon-facebook-f"></i></a>*/}
              {/*    <a href="https://twitter.com" className="social-icon social-twitter" rel="noopener noreferrer" title="Twitter" target="_blank"><i className="icon-twitter"></i></a>*/}
              {/*    <a href="https://instagram.com" className="social-icon social-instagram" rel="noopener noreferrer" title="Instagram" target="_blank"><i className="icon-instagram"></i></a>*/}
              {/*    <a href="https://youtube.com" className="social-icon social-youtube" rel="noopener noreferrer" title="Youtube" target="_blank"><i className="icon-youtube"></i></a>*/}
              {/*    <a href="https://pinterest.com" className="social-icon social-pinterest" rel="noopener noreferrer" title="Pinterest" target="_blank"><i className="icon-pinterest"></i></a>*/}
              {/*</div>*/}
            </div>
          </div>
        </footer>
      )}
    </>
  );
}

export default React.memo(Footer);
