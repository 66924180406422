import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import {Link, useHistory} from 'react-router-dom';
import {connect} from 'react-redux';
// Common Header Components
import {AuthContext} from '../../contexts/auth/auth.context';

import {removeFromCart, showModal, toggleCategoryFilter} from '../../redux/actions';
import {useAppState} from "../../contexts/app/app.provider";
import {getCartCount, getCartTotal, getWishlistCount} from "../../services";
import CartMenuSidebar from "./partials/cart-menu-sidebar";
import WishlistMenuSidebar from "./partials/wishlist-menu-sidebar";
//import {CCollapse} from '@coreui/react/src/components/collapse/CCollapse'
import {Collapse} from 'react-collapse';
// import logoImage from '../../assets/images/goldenpankh/logo/logo1.1.png';
// import logoImage from '../../assets/images/goldenpankh/logo.svg';

const socialNameIconMapper = {
  facebook: "social social_facebook",
  twitter: "social social_twitter",
  pinterest: "social social_pinterest",
  instagram: "instagram",
  linkedinS: "linkedin-square",
  youtube: "youtube-play",
  whatsapp: "fa fa-whatsapp",
  google: "social social_googleplus",
}

function Header(props) {


  const companyName = useAppState('companyName');
  const pct = useAppState('pct');
  const appCategories = useAppState('categories');
  const appSubCategories = useAppState('subCategories');
  const socialLinks = useAppState('socialLinks');
  let showAbout = false;

  const [searchVisible, toggleSearchBarVisibility] = useState(false);
  const [cartVisible, toggleCartVisibility] = useState(false);
  const [wishlistVisible, toggleWishlistVisibility] = useState(false);
  const [menuVisible, toggleMenuVisibility] = useState(false);
  const [miniCartVisible, toggleMiniCartVisibility] = useState(false);
  // const catImages = [
  //   "/assets/images/paramount/menu/homedecore.png",
  //   "/assets/images/paramount/menu/garden.png",
  //   "/assets/images/paramount/menu/furniture.png",
  //   "/assets/images/paramount/menu/tabletop.png",
  // ];
  const [checked, setChecked] = useState(false);

  if (pct && pct.storeFrontSettings && pct.storeFrontSettings.subDomain && ["testing", "anuraj"].includes(pct.storeFrontSettings.subDomain)) {
    showAbout = true;
  }

  let history = useHistory();
  const [path, setPath] = useState("");
  const {
    authState: {isAuthenticated, user},
    authDispatch,
  } = React.useContext(AuthContext);

  useEffect(() => {
    setPath(window.location.pathname);
  });

  function onLogout(e) {
    e && e.preventDefault();
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    authDispatch({type: 'SIGN_OUT'});
    history.push('/');
  }

  function openLoginModal(e) {
    props.showModal('login');
    e.preventDefault();
  }

  const isUserLoggedIn = !!isAuthenticated && !!user;
  const {wishlist, logo = `${process.env.PUBLIC_URL}assets/images/goldenpankh/logo/logo1.1.png`, container} = props;

  const socialNameIconMapper = {
    facebook: "facebook",
    twitter: "twitter",
    pinterest: "pinterest-p",
    instagram: "instagram",
    linkedinS: "linkedin-square",
    youtube: "youtube-play",
    whatsapp: "whatsapp",
    google: "google",
  }

  useEffect(() => {

    initMainSlider();

  }, [])
  let jq = undefined;
  const initMainSlider = () => {
    try {
      if (!jq) {
        jq = window.jQuery;
      }
      jq("#search-overlay-trigger").on('click', function () {
        jq("#search-overlay").addClass("active");
        jq("this").addClass('no-overflow');
      });

      jq("#search-close-trigger").on('click', function () {
        jq("#search-overlay").removeClass("active");
        jq("this").removeClass('no-overflow');
      });


      var $offcanvasNav = jq('.offcanvas-menu, .offcanvas-minicart_menu, .offcanvas-search_menu, .mobile-menu'),
        $offcanvasNavWrap = jq('.offcanvas-menu_wrapper, .offcanvas-minicart_wrapper, .offcanvas-search_wrapper, .mobile-menu_wrapper'
        ), $offcanvasNavSubMenu = $offcanvasNav.find('.sub-menu'),
        $menuToggle = jq('.menu-btn'),
        $menuClose = jq('.btn-close');

      /*Close Off Canvas Sub Menu*/
      $offcanvasNavSubMenu.slideUp();


      jq('.btn-close').on('click', function (e) {
        e.preventDefault();
        jq('.mobile-menu .sub-menu').slideUp();
        jq('.mobile-menu .menu-item-has-children').removeClass('menu-open');
      })


    } catch (e) {
      setTimeout(() => {
        console.log('initMainSlider called')
        initMainSlider();
      })
    }
  }

  const currencySymbol = useAppState('currencySymbol');
  const showPrice = pct && pct.productSettings && pct.productSettings.showPrice;
  const showQuantity = pct && pct.productSettings && pct.productSettings.showQuantity;
  let total = getCartTotal(props.cartlist);

  let categories = _.chunk(appCategories || [], 8);

  return (
    <>

      <div className="header-area header-area--default">

        <header
          className={props.isHomePage ? "header-area header_absolute header_height-100 " : "header-area  header_height-90 "}>
          <div className="container-fluid container-fluid--cp-100">
            <div className="row align-items-center">

              <div className="col-lg-3 col-lg-3 col-6">
                <div className="logo text-start">
                  <Link to={`${process.env.PUBLIC_URL}/`}>
                    <img src="/assets/images/goldenpankh/logo.svg" alt="logo" width="180px"/>
                  </Link>
                </div>
              </div>

              <div className="col-lg-6 col-lg-6  d-none d-lg-block">
                <div className="header__navigation d-none d-lg-block">
                  <nav className="navigation-menu d-flex align-items-center">
                    <ul className="justify-content-center">
                      <li>
                        <Link to="/"><span>Home</span></Link>
                      </li>
                      <li className="has-children">
                        <Link to="/products?category=all"><span>Products</span></Link>
                        {/*mega menu -->*/}
                        <ul className="megamenu megamenu--mega">
                          {
                            categories.map((cat, catIndex) => {
                              return <li key={catIndex}>
                                {/*<h2 className="page-list-title">{cat.title}</h2>*/}
                                <ul>
                                  {
                                    (cat).map((subCat, subCatIndex) => {
                                      return <li key={subCatIndex}>
                                        <Link
                                          to={`${process.env.PUBLIC_URL}/products?category=${subCat.slug}`}><span>{subCat.title}</span></Link>
                                      </li>
                                    })
                                  }
                                </ul>
                              </li>
                            })
                          }
                          <li key={"empty"}>
                          </li>
                          {/*<li>*/}
                          {/*  <h2 className="page-list-title">HOME DECOR</h2>*/}
                          {/*  <ul>*/}
                          {/*    <li><Link to="#"><span>Animals</span></Link></li>*/}
                          {/*    <li><Link to="#"><span>Candle stand*/}
                          {/*      &amp; Lantern</span></Link></li>*/}
                          {/*    <li><a href="#"><span>Christmas</span></a></li>*/}
                          {/*    <li><a href="#"><span>Circus &amp; Gaming</span></a></li>*/}
                          {/*    <li><a href="#"><span>Specific</span></a></li>*/}
                          {/*  </ul>*/}
                          {/*</li>*/}
                          {/*<li>*/}
                          {/*  <h2 className="page-list-title">GARDEN & OUTDOOR</h2>*/}
                          {/*  <ul>*/}
                          {/*    <li><a href="#"><span>Bird-Nest-Egg-Cage</span></a></li>*/}
                          {/*    <li><a href="#"><span>Heart &amp; Crown</span></a></li>*/}
                          {/*    <li><a href="#"><span>Leaf - Flower &amp; Vases</span></a></li>*/}
                          {/*    <li><a href="#"><span>Pot &amp; Plants</span></a></li>*/}
                          {/*    <li><a href="#"><span>Urns</span></a></li>*/}
                          {/*    <li><a href="#"><span>Utility</span></a></li>*/}
                          {/*    <li><a href="#"><span>Insects</span></a></li>*/}
                          {/*  </ul>*/}
                          {/*</li>*/}
                          {/*<li>*/}
                          {/*  <h2 className="page-list-title">KITCHENWARE</h2>*/}
                          {/*  <ul>*/}
                          {/*    <li><a href="#"><span>Kitchenware Cast Iron</span></a></li>*/}
                          {/*    <li><a href="#"><span>Furniture</span></a></li>*/}
                          {/*  </ul>*/}
                          {/*</li>*/}
                          {/*<li>*/}

                          {/*</li>*/}
                        </ul>
                      </li>
                      <li className={`${window.location.href.includes('/presentations') ? 'active' : ''}`}>
                        <Link to="/presentations" className="menu-item text-truncate">Presentations</Link>
                      </li>
                      <li className={`${window.location.href.includes('/collections') ? 'active' : ''}`}>
                        <Link to="/collections" className="menu-item text-truncate">Collections</Link>
                      </li>
                      <li className={`${window.location.href.includes('showrooms') ? 'active' : ''}`}>
                        <Link to="/showrooms" className="link-hover">Showrooms</Link>
                      </li>
                      <li className={`${window.location.href.includes('about') ? 'active' : ''}`}>
                        <Link to="/about" className="menu-item">About</Link>
                      </li>
                      <li className={`${window.location.href.includes('contact') ? 'active' : ''}`}>
                        <Link to="/contact" className="menu-item">Contact</Link>
                      </li>
                    </ul>
                  </nav>
                </div>

              </div>
              <div className="col-lg-3 col-lg-3 col-6">
                <div className="header-right-side text-end">
                  <div className="header-right-items">
                    <Link
                      to="#"
                      className="header-cart minicart-btn toolbar-btn header-icon"
                      onClick={e => {
                        e && e.preventDefault();
                        toggleWishlistVisibility(!wishlistVisible);
                      }}
                    >
                      <i className="icon-heart"></i>
                      <span className="item-counter">{props.wishlist.length}</span>
                    </Link>
                  </div>
                  <div className="header-right-items">
                    <Link
                      to="#"
                      className=" header-cart minicart-btn toolbar-btn header-icon"
                      onClick={e => {
                        e && e.preventDefault();
                        toggleMiniCartVisibility(!miniCartVisible);
                      }}
                    >
                      <i className="icon-bag2"></i>
                      <span className="item-counter">{props.cartlist.length}</span>
                    </Link>
                  </div>

                  <div className="header-right-items ">
                    <Link
                      to="#"
                      className="mobile-navigation-icon" id="mobile-menu-trigger"
                      onClick={e => {
                        e && e.preventDefault();
                        toggleMenuVisibility(!menuVisible);
                      }}
                    >
                      <i className="icon-menu"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/*Header Bottom Wrap End -->*/}
      </div>

      {/* right modal start here-----*/}
      <div className={`mobile-menu-overlay ${menuVisible ? "active" : ""}`} id="mobile-menu-overlay">
        <div className="mobile-menu-overlay__inner">
          <div className="mobile-menu-close-box text-start">
            <span className="mobile-navigation-close-icon" id="mobile-menu-close-trigger"
                  onClick={e => {
                    toggleMenuVisibility(false);
                  }}>
              <i className="icon-cross2"/>
            </span>
          </div>
          <div className="mobile-menu-overlay__body">
            <div className="offcanvas-menu-header d-md-block d-none">
              <div className="helendo-language-currency row-flex row section-space--mb_60 ">
              </div>
            </div>
            <nav className="offcanvas-navigation">
              <ul>
                <li>
                  <Link to={`${process.env.PUBLIC_URL}/`}>Home</Link>
                </li>
                <li className={`${window.location.href.includes('/products?category=all') ? 'active' : ''}`}>
                  <Link to="/products?category=all"><span>Product</span></Link>
                </li>
                <li className={`${window.location.href.includes('/presentations') ? 'active' : ''}`}>
                  <Link to="/presentations" className="menu-item text-truncate">Presentations</Link>
                </li>
                <li className={`${window.location.href.includes('/collections') ? 'active' : ''}`}>
                  <Link to="/collections" className="menu-item text-truncate">Collections</Link>
                </li>
                <li className={`${window.location.href.includes('showrooms') ? 'active' : ''}`}>
                  <Link to="/showrooms" className="link-hover"
                  >Showrooms</Link>
                </li>
                <li className={`${window.location.href.includes('about') ? 'active' : ''}`}>
                  <Link to="/about" className="menu-item">About</Link>
                </li>
                <li className={`${window.location.href.includes('contact') ? 'active' : ''}`}>
                  <Link to="/contact" className="menu-item">Contact</Link>
                </li>
              </ul>
            </nav>

            <div className="mobile-menu-contact-info section-space--mt_60">
              <h6>Contact Us</h6>
              <ul className="footer_hours">
                <li>D-8, Harthala Industrial Estate,<p>Kanth Road, Moradabad 244001, Uttar
                  Pradesh
                  India </p></li>
                <li>+91-591-2970888<p>+91-7417555555</p></li>
                <li>naresh@goldenpankh.com<p>&nbsp;</p></li>
              </ul>
            </div>

            <div className="mobile-menu-social-share section-space--mt_60">
              <h6>Follow US On Socials</h6>
              <ul className="social-share">
                <li><a href="https://www.facebook.com/Golden-Pankh-Export-Import-349233159129646/">
                  <i className="social social_facebook"/></a></li>
                {/*<li><a href="#"><i className="social social_twitter"/></a></li>*/}
                {/*<li><a href="#"><i className="social social_googleplus"/></a></li>*/}
                <li>
                  <a href="https://api.whatsapp.com/send?phone=917417555555&text=I+have+a+query+regarding+Golden+Pankh"
                     target="_blank" aria-label="whatsapp">
                    <i className="fa fa-whatsapp" style={{fontSize: "20px"}}/>
                  </a>
                </li>
                {/*<li><a href="#"><i className="social social_pinterest"></i></a></li>*/}
              </ul>
            </div>

          </div>


        </div>


      </div>
      {/* right modal end here------*/}

      {/*search over lay start here------*/}
      <div className="search-overlay" id="search-overlay">

        <div className="search-overlay__header">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-8">
                <div className="search-title">
                  <h4 className="font-weight--normal">Search</h4>
                </div>
              </div>
              <div className="col-md-6 ms-auto col-4">
                {/*search content -->*/}
                <div className="search-content text-end">
                  <span className="mobile-navigation-close-icon" id="search-close-trigger">
                    <i className="fa fa-close" style={{marginLeft: "90%"}}/></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="search-overlay__inner">
          <div className="search-overlay__body">
            <div className="search-overlay__form">
              <div className="container">
                <div className="row">
                  <div className="col-lg-9 m-auto">
                    <form action="#">
                      <div className="product-cats section-space--mb_60 text-center">
                        {/*<label>*/}
                        {/*  <input type="radio" name="product_cat" value="" checked="checked"/>*/}
                        {/*  <span className="line-hover">All</span>*/}
                        {/*</label>*/}
                        {/*<label>*/}
                        {/*  <input type="radio" name="product_cat" value="decoration"/>*/}
                        {/*  <span className="line-hover">Decoration</span>*/}
                        {/*</label>*/}
                        {/*<label>*/}
                        {/*  <input type="radio" name="product_cat" value="furniture"/>*/}
                        {/*  <span className="line-hover">Furniture</span>*/}
                        {/*</label>*/}
                        {/*<label>*/}
                        {/*  <input type="radio" name="product_cat" value="table"/>*/}
                        {/*  <span className="line-hover">Table</span>*/}
                        {/*</label>*/}
                        {/*<label>*/}
                        {/*  <input type="radio" name="product_cat" value="chair"/>*/}
                        {/*  <span className="line-hover">Chair</span>*/}
                        {/*</label>*/}
                      </div>
                      <div className="search-fields">
                        <input type="text" placeholder="Search"/>
                        <button className="submit-button">
                          <i className="fa fa-search"/>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      {/*search over lay end here------*/}

      {/*mini cart add to cart  modal start -----*/}

      {/*<CartMenuSidebar*/}
      {/*  cartVisible={miniCartVisible}*/}
      {/*  toggleCartVisibility={toggleMiniCartVisibility()}*/}
      {/*/>*/}

      <div className={`offcanvas-minicart_wrapper ${miniCartVisible ? "open" : ""}`} id="miniCart">
        <div className="offcanvas-menu-inner">
          <div className="close-btn-box">
            <a className="btn-close"
               onClick={e => {
                 toggleMiniCartVisibility(false);
               }}
            >
              <i className="icon-cross2"/>
            </a>
          </div>

          {
            0 === props.cartlist.length ? <p>No products in the cart.</p> : <>
              <div className="minicart-content">
                <ul className="minicart-list">
                  {
                    props.cartlist.map((item, index) => {
                      return <li className="minicart-product" key={index}>
                        <a
                          className="product-item_remove"
                          onClick={e => {
                            console.log("clicked")
                            console.log("item = ", item);
                            // e && e.preventDefault();
                            props.removeFromCart(item.productId);
                          }}
                        >
                          <i className="icon-cross2"/>
                        </a>
                        <a className="product-item_img">
                          <img width="76px" className="img-fluid" src={`${process.env.CLOUD_FRONT_URL}${item.thumb}`}
                               alt={item.name}/>
                        </a>
                        <div className="product-item_content">
                          <div className="product-item_title">{item.name}</div>
                          {
                            showQuantity && <label>Qty : <span>{item.qty}</span></label>
                          }
                          {
                            showPrice && <label className="product-item_quantity">
                              Price: <span> {currencySymbol}
                              {(item.priceValue || 0).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              })}
                              </span>
                            </label>
                          }
                        </div>
                      </li>
                    })
                  }
                </ul>
              </div>
              {
                showPrice && <div className="minicart-item_total">
                  <span className="font-weight--reguler">Subtotal:</span>
                  <span className="ammount font-weight--reguler">
                  {currencySymbol}
                    {(total || 0).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                </span>
                </div>
              }
              <div className="minicart-btn_area">
                <Link to={`${process.env.PUBLIC_URL}/cart`} className="btn btn--full btn--border_1">View cart</Link>
              </div>
              {/*<div className="minicart-btn_area">*/}
              {/*  <a href="checkout.html" className="btn btn--full btn--black">Checkout</a>*/}
              {/*</div>*/}
            </>
          }
        </div>

        <div className={`global-overlay ${miniCartVisible ? "overlay-open" : ""}`}/>
      </div>
      {/*mini cart add to cart end here------*/}

      <WishlistMenuSidebar
        visible={wishlistVisible}
        toggle={toggleWishlistVisibility}
      />

      {/*<header id="navbar-spy" className="header header-1 header-transparent d-block d-lg-none">*/}
      {/*  <nav id="primary-menu" className="navbar navbar-expand-lg navbar-light">*/}
      {/*    <div className="container-fluid">*/}
      {/*      <Link className="navbar-brand" style={{display: 'flex', minWidth: 0}} to="/">*/}
      {/*        <img className="logo logo-light home" src={`${process.env.CLOUD_FRONT_URL}${pct.logo}`}*/}
      {/*             alt={companyName}*/}
      {/*        />*/}
      {/*      </Link>*/}
      {/*      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarContent"*/}
      {/*              aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">*/}
      {/*        <span className="navbar-toggler-icon"></span>*/}
      {/*      </button>*/}

      {/*      <div className="collapse navbar-collapse" id="navbarContent">*/}
      {/*        <ul className="navbar-nav mr-auto">*/}

      {/*          <li className="has-dropdown mega-dropdown active">*/}
      {/*            <a href="#" data-toggle="dropdown" className="dropdown-toggle menu-item">Products</a>*/}
      {/*            <ul className="dropdown-menu mega-dropdown-menu">*/}
      {/*              <li>*/}
      {/*                <div className="container">*/}
      {/*                  <div className="row">*/}

      {/*                    {*/}
      {/*                      (appCategories || []).map((cat, index) => (*/}
      {/*                          <div className="col-md-12 col-lg-3" key={index}>*/}
      {/*                            <Link to={`/products?category=${cat.slug}`} className="border-0 p-0">*/}
      {/*                              <h6 className="mt-2 mb-0">{cat.title}</h6>*/}
      {/*                            </Link>*/}
      {/*                            <ul>*/}
      {/*                              {*/}
      {/*                                ((appSubCategories || []).filter(c => c.parentCategoryId === cat._id)).map((sCat, sIndex) => (*/}
      {/*                                  <li key={sIndex}>*/}
      {/*                                    <Link to={`/products?category=${sCat.slug}`}>{sCat.title}</Link>*/}
      {/*                                  </li>*/}
      {/*                                ))*/}

      {/*                              }*/}
      {/*                            </ul>*/}
      {/*                          </div>*/}
      {/*                        )*/}
      {/*                      )*/}
      {/*                    }*/}

      {/*                  </div>*/}

      {/*                </div>*/}

      {/*              </li>*/}
      {/*            </ul>*/}

      {/*          </li>*/}


      {/*          <li className="has-dropdown">*/}
      {/*            <Link to="/presentations" className="link-hover text-truncate">Presentations</Link>*/}
      {/*          </li>*/}

      {/*          <li className="has-dropdown">*/}
      {/*            <Link to="/collections" className="link-hover text-truncate">Collections</Link>*/}
      {/*          </li>*/}

      {/*          <li className="has-dropdown">*/}
      {/*            <Link to="/showrooms" className="link-hover"*/}
      {/*            >Showrooms</Link>*/}
      {/*          </li>*/}

      {/*          <li className="has-dropdown">*/}
      {/*            <Link to="/sustainability" className="link-hover">Sustainability</Link>*/}
      {/*          </li>*/}

      {/*          <li className="">*/}
      {/*            <Link to="/about" className="menu-item">About</Link>*/}
      {/*          </li>*/}

      {/*          <li className="">*/}
      {/*            <Link to="/contact" className="menu-item">Contact</Link>*/}
      {/*          </li>*/}
      {/*        </ul>*/}
      {/*        <div className="module-container d-flex flex-row">*/}
      {/*          <div*/}
      {/*            className={`module module-cart module-cart-3 pull-left ${wishlistVisible ? 'module-active' : ''}`}>*/}
      {/*            <div*/}
      {/*              className="module-icon cart-icon"*/}
      {/*              onClick={() => {*/}
      {/*                toggleWishlistVisibility(!wishlistVisible);*/}
      {/*              }}*/}
      {/*            >*/}
      {/*              <i className="ti-heart"></i>*/}
      {/*              <span className="title">wishlist</span>*/}
      {/*              <label className="module-label">{getWishlistCount(props.wishlist)}</label>*/}
      {/*            </div>*/}
      {/*            <WishlistMenuSidebar*/}
      {/*              visible={wishlistVisible}*/}
      {/*              toggle={toggleWishlistVisibility}*/}
      {/*            />*/}
      {/*          </div>*/}
      {/*          <div className={`module module-cart module-cart-3 pull-left ${cartVisible ? 'module-active' : ''}`}>*/}
      {/*            <div className="module-icon cart-icon" onClick={() => {*/}
      {/*              toggleCartVisibility(!cartVisible)*/}
      {/*            }}>*/}
      {/*              <i className="icon-bag"></i>*/}
      {/*              <span className="title">shop cart</span>*/}
      {/*              <label className="module-label">{getCartCount(props.cartlist)}</label>*/}
      {/*            </div>*/}
      {/*            <CartMenuSidebar*/}
      {/*              cartVisible={cartVisible}*/}
      {/*              toggleCartVisibility={toggleCartVisibility}*/}
      {/*            />*/}
      {/*          </div>*/}

      {/*        </div>*/}
      {/*      </div>*/}

      {/*    </div>*/}

      {/*  </nav>*/}
      {/*</header>*/}


    </>
  );
}

function mapStateToProps(state) {
  return {
    wishlist: state.wishlist.list ? state.wishlist.list : [],
    cartlist: state.cartlist.cart ? state.cartlist.cart : []
  }
}

export default connect(mapStateToProps, {showModal, toggleCategoryFilter, removeFromCart})(Header);